import React, { Component } from "react";
import AudioAnalyser from "react-audio-analyser";
import axios from "axios";
import { getCurrentUser } from "../../services/authService";
import { submitSpeechScoreNew } from "../../services/scoreService/scoreSAService";
import "../../designStyles/SpeechAssessment/SpeechSeen.css";
import { showSuccessToast, showErrorToast } from "../utils/toastMessage";
import { withTranslation } from 'react-i18next';
const URL = axios.defaults.baseURL + "/userScoreSpeechAnalysis";

 class SpeechPanel extends Component {
   constructor(props) {
     super(props);
     this.state = {
       text: "",
       isRecording: false,
       isSubmitting: false,
       userInfo: { userId: "" },
       status: "neutral",
       audioType: "audio/wav",
       submitDataFlag: false,
       confirmShown: false,
     };
   }

   setText = () => {
     const { i18n } = this.props;
     const texts = [
       `As customers of all nationalities, and cultures visit the Dekalb Farmers Market by the thousands, I doubt that many stand in awe and contemplate the meaning of its existence. But in the capital of the Sunbelt South, the quiet revolution of immigration and food continues to upset and redefine the meanings of local, regional, and global identity.`,
       `Ikigai is a Japanese concept referring to having a direction or purpose in life, providing a sense of fulfillment and towards which they the person may take actions, giving them satisfaction and a sense of meaning.`,
       `The Sun is the star at the center of the Solar System. It is a nearly perfect ball of hot plasma, heated to incandescence by nuclear fusion reactions in its core. The Sun radiates this energy mainly as light, ultraviolet, and infrared radiation, and is the most important source of energy for life on Earth.`,
       `Machine learning is the study of computer algorithms that can improve automatically through experience and by the use of data. It is seen as a part of artificial intelligence. Machine learning algorithms build a model based on sample data, known as training data, in order to make predictions or decisions without being explicitly programmed to do so.`,
       `Mass is the quantity of matter in a physical body. It is also a measure of the body's inertia, the resistance to acceleration when a net force is applied. An object's mass also determines the strength of its gravitational attraction to other bodies.`,
       `Although the equivalence principle guided the development of general relativity, it is not a founding principle of relativity but rather a simple consequence of the geometrical nature of the theory. In general relativity, objects in free-fall follow geodesics of spacetime, and what we perceive as the force of gravity is instead a result of our being unable to follow those geodesics of spacetime, because the mechanical resistance of Earth's matter or surface prevents us from doing so.`,
     ];
     const textsInArabic = [
       `نظرًا لأن العملاء من جميع الجنسيات والثقافات يزورون سوق المزارعين ديكالب بالآلاف، أشك في أن الكثيرين يقفون في حالة من الرهبة ويفكرون في معنى وجوده. ولكن في عاصمة الحزام الشمسي الجنوبي، تستمر ثورة الهجرة والغذاء الهادئة في إزعاج وإعادة تعريف معاني الهوية المحلية والإقليمية والعالمية.`,
       `إيكيجاي هو مفهوم ياباني يشير إلى وجود اتجاه أو هدف في الحياة، مما يوفر إحساسًا بالإنجاز والذي يمكن للشخص أن يتخذ إجراءات تجاهه، مما يمنحه الرضا والشعور بالمعنى.`,
       `الشمس هي النجم الذي يقع في مركز النظام الشمسي. إنها عبارة عن كرة شبه مثالية من البلازما الساخنة، يتم تسخينها إلى درجة التوهج عن طريق تفاعلات الاندماج النووي في قلبها. وتشع الشمس هذه الطاقة بشكل أساسي على شكل ضوء، وأشعة فوق بنفسجية، وأشعة تحت الحمراء، وهي أهم مصدر للطاقة للحياة على الأرض.`,
       `التعلم الآلي هو دراسة خوارزميات الكمبيوتر التي يمكن أن تتحسن تلقائيًا من خلال الخبرة واستخدام البيانات. ويُنظر إليه على أنه جزء من الذكاء الاصطناعي. تقوم خوارزميات التعلم الآلي ببناء نموذج يعتمد على بيانات العينة، المعروفة باسم بيانات التدريب، من أجل عمل تنبؤات أو قرارات دون أن تتم برمجتها بشكل صريح للقيام بذلك.`,
       `الكتلة هي كمية المادة الموجودة في الجسم المادي. وهو أيضًا مقياس لقصور الجسم الذاتي، أي مقاومة التسارع عند تطبيق قوة محصلة. تحدد كتلة الجسم أيضًا قوة جاذبيته للأجسام الأخرى.`,
       `على الرغم من أن مبدأ التكافؤ هو الذي أرشد تطور النسبية العامة، إلا أنه ليس مبدأً مؤسسًا للنسبية، بل نتيجة بسيطة للطبيعة الهندسية للنظرية. في النسبية العامة، تتبع الأجسام في حالة سقوط حر جيوديسية الزمكان، وما ندركه كقوة جاذبية هو بدلاً من ذلك نتيجة لعدم قدرتنا على متابعة جيوديسية الزمكان، لأن المقاومة الميكانيكية لمادة الأرض أو سطحها تمنعنا من ذلك. القيام بذلك.`,
     ];
     const textsInFrench = [
       `Alors que des clients de toutes nationalités et cultures visitent le marché fermier de Dekalb par milliers, je doute que beaucoup soient impressionnés et contemplent le sens de son existence. Mais dans la capitale de la Sunbelt South, la révolution tranquille de l’immigration et de l’alimentation continue de bouleverser et de redéfinir le sens de l’identité locale, régionale et mondiale.`,
       `L'ikigai est un concept japonais faisant référence au fait d'avoir une direction ou un but dans la vie, procurant un sentiment d'épanouissement et vers lequel la personne peut entreprendre des actions, lui donnant satisfaction et un sens.`,
       `Le Soleil est l'étoile au centre du système solaire. Il s’agit d’une boule presque parfaite de plasma chaud, chauffée à incandescence par des réactions de fusion nucléaire en son cœur. Le Soleil rayonne cette énergie principalement sous forme de lumière, de rayonnement ultraviolet et infrarouge et constitue la source d’énergie la plus importante pour la vie sur Terre.`,
       `L’apprentissage automatique est l’étude d’algorithmes informatiques qui peuvent s’améliorer automatiquement grâce à l’expérience et à l’utilisation de données. Elle est considérée comme faisant partie de l’intelligence artificielle. Les algorithmes d'apprentissage automatique construisent un modèle basé sur des échantillons de données, appelés données d'entraînement, afin de faire des prédictions ou des décisions sans être explicitement programmés pour le faire.`,
       `La masse est la quantité de matière présente dans un corps physique. C'est également une mesure de l'inertie du corps, la résistance à l'accélération lorsqu'une force nette est appliquée. La masse d’un objet détermine également la force de son attraction gravitationnelle vers d’autres corps.`,
       `Bien que le principe d’équivalence ait guidé le développement de la relativité générale, il n’est pas un principe fondateur de la relativité mais plutôt une simple conséquence de la nature géométrique de la théorie. En relativité générale, les objets en chute libre suivent les géodésiques de l'espace-temps, et ce que nous percevons comme la force de gravité est plutôt le résultat de notre incapacité à suivre ces géodésiques de l'espace-temps, car la résistance mécanique de la matière ou de la surface terrestre nous empêche de Ce faisant.`,
     ];
     const text =
       i18n.language === "fr"
         ? textsInFrench[Math.floor(Math.random() * textsInFrench.length)]
         : i18n.language === "ar"
         ? textsInArabic[Math.floor(Math.random() * textsInArabic.length)]
         : texts[Math.floor(Math.random() * texts.length)];

     this.setState({
       text: text,
     });
   };

   handleAudioSubmit = async (blob, blobURL) => {
    const { t } = this.props
     this.setState({ submitDataFlag: true });
     const uId = this.state.userInfo.userId;
     const textScript = this.state.text;
     const { i18n } = this.props
     const currentLanguage = i18n?.language || "en"
     try {
       let response = await submitSpeechScoreNew(
         blob,
         blobURL,
         uId,
         textScript,
         currentLanguage
       );
       showSuccessToast(t(`common.${response.data.message}`));
       setTimeout(() => {
         window.location = "/allAssessmentTypes";
       }, 3000);
     } catch (ex) {
       if (
         ex.response &&
         ex.response.status >= 400 &&
         ex.response.status <= 500
       ) {
         showErrorToast(t(`common.${ex.response.data.message}`));
       }
     }
   };

   controlAudio(status) {
     this.setState({
       status,
     });
   }

   changeScheme(e) {
     this.setState({
       audioType: e.target.value,
     });
   }

   componentDidMount = async () => {
    const { i18n } = this.props;
     const language = localStorage.getItem("selectedLanguage");
     i18n.changeLanguage(language);
     
     window.addEventListener("beforeunload", this._confirm);
     window.addEventListener("popstate", this._backConfirm);
     window.history.pushState(null, "", window.location.href);

     try {
       const user = await getCurrentUser();
       this.setState({ userInfo: { userId: user._id } });
       this.setText();
     } catch (ex) {
       console.log("Error");
     }
   };

   componentWillUnmount() {
     window.removeEventListener("beforeunload", this._confirm);
     window.removeEventListener("popstate", this._backConfirm);
   }

   _backConfirm = (e) => {
    const { t } = this.props
     if (!this.state.submitDataFlag && !this.state.confirmShown) {
       e.preventDefault();
       let event = window.confirm(t("common.Changes that you may not be saved."));
       if (event) {
         this.setState({ confirmShown: true });
         window.history.back();
       } else {
         window.history.pushState(null, "", window.location.href);
       }
     }
   };

   _confirm = (e) => {
    const { t } = this.props
     if (!this.state.submitDataFlag) {
       var confirmationMessage = t("common.Do you want to leave this page.");
       e.returnValue = confirmationMessage;
       return confirmationMessage;
     }
   };

   render() {
     const { status, audioSrc, audioType, text } = this.state;
     const { t } = this.props
     const audioProps = {
       audioType,
       status,
       audioSrc,
       timeslice: 1000,
       backgroundColor: "#e3e3e3",
       strokeColor: "#7386D5",
       startCallback: (e) => {
         console.log("succ start", e);
       },
       pauseCallback: (e) => {
         console.log("succ pause", e);
       },
       stopCallback: (e) => {
         this.setState({});
         this.handleAudioSubmit(e, this.state.audioSrc);
         console.log("succ stop", e);
       },
       onRecordCallback: (e) => {
       },
       errorCallback: (err) => {
         console.log("error", err);
       },
     };
     if (!text) return <p>{t("speechAssessmentTest.Loading...")}</p>;

     return (
       <div>
         <AudioAnalyser {...audioProps}>
           <div className="body_widget text-center">
             <strong>{t("speechAssessmentTest.Read The Text Below")}:</strong>
           </div>
           <div className="card">
             <div className="card_widget">
               <p>{text}</p>
             </div>
           </div>
           <div className="btn-box">
             <button
               className="button button--wayra"
               onClick={() => this.controlAudio("recording")}
               disabled={status === "inactive"}
             >
               {status === "recording" ? t("speechAssessmentTest.Recording...") : t("speechAssessmentTest.Record")}
             </button>
             <button
               className="button button--wayra"
               onClick={() => this.controlAudio("inactive")}
               disabled={status === "neutral"}
             >
               {status === "inactive" ? t("speechAssessmentTest.Submitting...") : t("speechAssessmentTest.Stop Record")}
             </button>
             <select
               name=""
               id=""
               onChange={(e) => this.changeScheme(e)}
               value={audioType}
             >
               <option value="audio/wav">{t("speechAssessmentTest.audio/wav")}</option>
             </select>
           </div>
         </AudioAnalyser>
       </div>
     );
   }
 }

export default withTranslation()(SpeechPanel);
